import { uuid } from "vue-uuid";
export default {
  methods: {
    /**
     * Handles api call logic to create a new site.
     *
     * @returns a promise so that we can continue promise chaining if necessary.
     */
    createSiteID() {
      return Promise.resolve(
        this.$api
          .post("/siteID", this.form)
          .then((res) => {
            // alert("New Site successfully created!");
            this.$router.push({ path: "/" });
            const url = `${process.env.VUE_APP_APP_ROOT}/login/${res.data.id}`;
            this.copyToClipboard(url);
            window.open(url, "_blank");
          })
          .catch(() => {
            alert("Error creating new site. Please contact support");
          })
      );
    },
    /**
     * Uses v-modeled form items to generate initial values and adds them to this.form.
     * Other defaults are defined on the backend.
     */
    generateFormToSend() {
      const primary_colorRGBA = this.hexToRgbA(this.form.primaryColor);
      this.form.primaryColor = primary_colorRGBA;
      this.form.backColor = primary_colorRGBA;
      this.form.fadeOverlayColor = this.TransparencyMutator(
        primary_colorRGBA,
        ".5"
      );
      this.form.startColor = primary_colorRGBA;
      this.form.categoryColor = this.TransparencyMutator(
        primary_colorRGBA,
        ".4"
      );
      this.form.categoryTextColor = primary_colorRGBA;
      this.form.personStripeTextColor = primary_colorRGBA;
      this.form.categoryTextColor = primary_colorRGBA;
      this.form.personNameColor = primary_colorRGBA;
      this.form.personNavButtonColor = primary_colorRGBA;
      this.form.dropdownColor = primary_colorRGBA;
      this.form.personCardColor = this.TransparencyMutator(
        primary_colorRGBA,
        ".4"
      );
      this.form.pillCategoryTextColor = primary_colorRGBA;
      this.form.homeCategoryTextColor = primary_colorRGBA;
      // NEW
      this.form.homeCategoryTextColor = primary_colorRGBA;
      this.form.titleColor = primary_colorRGBA;
      this.form.backgroundColor = this.TransparencyMutator(
        primary_colorRGBA,
        "0"
      );
      this.form.searchColor = primary_colorRGBA;
      this.form.headerStripeColor = primary_colorRGBA;
      this.form.homeButtonsColor = primary_colorRGBA;

      // CategoryLogoCards
      this.form.categoryLogoCardBackground = this.TransparencyMutator(
        primary_colorRGBA,
        ".95"
      );

      // Banners
      this.form.bannerTitleColor = primary_colorRGBA;
      this.form.bannerStripeColor = primary_colorRGBA;
      this.form.bannerBodyBackgroundColor = primary_colorRGBA;

      // End new
      this.form.homeButtons = [
        {
          buttonId: uuid.v1(),
          data: { url: this.twitterURL, confirmed: false },
          type: "Twitter",
          label: "Twitter",
        },
        {
          buttonId: uuid.v1(),
          data: "",
          type: "QR Code",
          label: "QR Code",
        },
      ];
    },
  },
};
